<template>

<!-- 校园管理  校级管理员 -->
  <div class="container">
    <div class="header-container">
      <!-- <div class="seek-container">
        <div class="seek-section">
          <i class="el-icon-search"></i>
          <el-input placeholder="请输入昵称"></el-input>
        </div>
        <el-button class="seek-button">
          搜索
        </el-button>
      </div> -->
      <div class="add-container">
        <router-link :to="{name: 'managementStudentSchoolSchoolAdministratorAdd'}">
          <el-button class="addUser-button">
            新增
          </el-button>
        </router-link>
      </div>
    </div>
    <div class="body-container">
      <el-table
        :data="teacherList"
        stripe
        style="width: 100%">
        <!-- <el-table-column
          prop="gradeName"
          label="年级"
          empty-text="-">
        </el-table-column>
        <el-table-column
          prop="className"
          label="班级">
        </el-table-column> -->
        <el-table-column
          label="序号"
          width="80">
          <template slot-scope="scope">
            <p class="index" style="width: 100%; text-align: left;">
              {{scope.$index + 1}}
            </p>
          </template>
        </el-table-column>
        <el-table-column
          prop="sysName"
          label="姓名">
        </el-table-column>
        <el-table-column
          prop="phone"
          label="手机号">
        </el-table-column>
        <el-table-column
          prop="phone"
          label="权限">
          <template slot-scope="scope">
            <el-switch
              @change="editTeacherAble(scope.row.isAble, scope.row.id)"
              v-model="scope.row.isAble"
              :active-value="0"
              :inactive-value="1"
              active-color="#34BBEC"
              inactive-color="#f5f5f5">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="设置">
          <template slot-scope="scope">
            <router-link :to="{name: 'managementStudentSchoolSchoolAdministratorEdit', params: {item: scope.row}}">
              <el-button
              size="mini"
              type="text"
              >编辑</el-button>
            </router-link>
            <el-divider direction="vertical"></el-divider>
            <el-button
             @click="showDel(scope.row.id)"
              size="mini"
              type="text"
              >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="footer-container">
      <pagination :current-page="show.startPage" :total="show.totalPage"  @currentChange="refreshGetData" @sizeChange="sizeChange"></pagination>
    </div>
  </div>
</template>
<script>
import storage from '@/globals/storage/index.js'
// import teacherService from '@/globals/service/management/teacher.js'
import SysService from '@/globals/service/detection/Sys.js'

export default {
  data () {
    return {
      teacherList: [],
      show: {
        pageSize: 8,
        schoolId: null,
        startPage: 1,
        totalPage: 1
      }
    }
  },
  created () {
    this.getData()
  },
  methods: {
    sizeChange (val) {
      this.show.pageSize = val
      this.getData()
    },
    editTeacherAble (value, id) {
      SysService.type({ type: value, ableId: id }).then(res => {
        this.$message({
          type: 'success',
          message: '修改成功!'
        })
      })
    },
    showDel (id) {
      this.$confirm('此操作将永久删除该管理员, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.del(id)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    del (id) {
      SysService.del({ list: [id] }).then(res => {
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
        this.show.startPage = 1
        this.getData()
      })
    },
    refreshGetData (index) {
      this.show.startPage = index
      this.getData()
    },
    getData () {
      this.show.schoolId = storage.get('schoolId')
      // const data = this.show
      SysService.list({ startPage: this.show.startPage, pageSize: this.show.pageSize }).then(res => {
        this.teacherList = res.data.list.map(item => {
          // item.isAble = Number(item.isAble)
          return item
        })
        this.show.totalPage = res.data.totalPage
      })
    }
  }
}
</script>
<style lang="less" scoped>
.container{
  min-height: 100%;
  background-color: #fff;
  padding: 0 42px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .header-container{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 10px;
    .seek-container{
      display: flex;
      align-items: center;
      .seek-section{
        border: 1px solid #34BBEC;
        border-radius: 20px;
        display: flex;
        align-items: center;
        padding: 0 22px;
        color: #333;
        /deep/.el-input__inner{
          border: none !important;
          padding: 0 10px !important;
        }
        .el-icon-search{
          color: #999999;
        }
      }
      .seek-button{
        width: 80px;
        background-color: #34BBEC;
        margin-left: 20px;
        border-radius: 20px;
        color: #fff;
      }
    }
    .add-container{
      .addUser-button{
        background-color: #34BBEC;
        border-radius: 20px;
        color: #fff;
      }
    }
  }
  .body-container{
    flex: 1;
  }
  .footer-container{
    display: flex;
    width: 100%;
    padding: 33px 0;
    .el-button{
      margin-right: 160px;
    }
  }
}
</style>
